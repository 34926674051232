import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  PAGENAME, REPORTNAME, REPORTNAMELIST, TOOLTIPMSG,
} from '../../bi/constants/reports';

import {
  getMoment, getYesterday, startOfMonth,
} from '../../bi/utils/formatDate';

import SearchReport from '../../components/SearchReport';
import SupplierDocuments from '../../components/SupplierDocuments';
import TripByDate from '../../components/tripByDate';
import CallApartments from '../../components/CallApartments';

import AccountsReportPage from './components/accounts';
import DebtorReportPage from './components/debtor';
import PrintReportPage from './components/print';
import ReviseActTest from './components/reviseActTest';
import TripReportPage from './components/sale';
import SchemasReportPage from './components/schemas';
import DocumentsForPrintsReport from './components/documentsForPrints/index';
import Overdraft from '../../components/OverDraft';
import AutoSendingEdo from './components/autoSendingEdo';
import GeneralActCompletedWorks from './components/generalActCompletedWorks';
import ReInsurance from './components/reconciliationWithInsurance';
import PaymentCard from './components/paymentCard';
import AviaReport from './components/aviaReport';
import { AboardReportPage } from './components/aboard/AboardReportPage';

const maxDate = getMoment();
const startMonth = startOfMonth();

const LIMIT = {
  MONTH: 31,
  QUARTER: 92,
};

const renderComponents = (box, opts) => {
  let page = null;

  const {
    params: { area },
    businessService,
    reviseActTestService,
    tripService,
    moveToReportResult,
  } = opts;

  switch (area) {
    case PAGENAME.ABOARD: {
      page = (
        <AboardReportPage
          onDownload={ businessService.getAboardByDate }
          onSend={ businessService.toggleAboardCheckbox }
        />
      );

      break;
    }

    case PAGENAME.TRIP: {
      page = (
        <TripReportPage
          onDownload={ businessService.downloadTripBi }
        />
      );
      break;
    }
    case PAGENAME.BOOKING: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadBookingBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.BOOKING_WL: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadBookingBiWl }
          nameList={ REPORTNAMELIST.REPORTNAME }
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.BOOKING_SPECIAL_BILLING: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadBookingBiSpecialBilling }
          nameList={ REPORTNAMELIST.REPORTNAME }
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.BOOKINGSEC: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadBookingBiSec }
          nameList={ REPORTNAMELIST.REPORTNAME }
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.BOOKINGTHIRD: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadBookingBiThird }
          nameList={ REPORTNAMELIST.REPORTNAME }
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.DEBTOR: {
      page = (
        <DebtorReportPage
          onDownload={ businessService.downloadDebtorBi }
        />
      );
      break;
    }
    case PAGENAME.COMPANIESDAYSINDEBT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadCompaniesDaysInDebt }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.ACCOUNTS: {
      page = (
        <AccountsReportPage
          onDownload={ businessService.downloadAccountsBi }
        />
      );
      break;
    }
    case PAGENAME.INCOMING: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadIncomingBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.PRINT: {
      page = (
        <PrintReportPage
          onDownload={ businessService.downloadPrintBi }
        />
      );
      break;
    }
    case PAGENAME.STYLEREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadStyleBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
        />
      );
      break;
    }
    case PAGENAME.TAGSREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadTagsBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
        />
      );
      break;
    }
    case PAGENAME.REPLYREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadReplyBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.KPIPOREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadKpipoBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.KPINOTEREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadKpiNoteBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.TIMECHATREPORT: {
      const dateMax = getYesterday(maxDate);
      page = (
        <SearchReport
          onDownload={ businessService.downloadTimeChatBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ dateMax }
          start={ dateMax }
          end={ dateMax }
        />
      );
      break;
    }
    case PAGENAME.STOPWORDREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadStopWordBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.SENDINGTYPEREPORT: {
      page = (
        <DebtorReportPage
          onDownload={ businessService.downloadSendingTypes }
        />
      );
      break;
    }
    case PAGENAME.EDITINGCLOSINGREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadEditingClosing }
          nameList={ REPORTNAMELIST.REPORTNAME }
          maxDate={ maxDate }
          start={ startMonth }
          disabledButton
          tooltipMsg={ TOOLTIPMSG.MORETHANTHIRTYONE }
        />
      );
      break;
    }
    case PAGENAME.CONSULTATIONSREPORT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadConsultationsBi }
          nameList={ REPORTNAMELIST.SERVICENAME }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.HOTELS: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadHotelsBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
          periodMax={ 2 }
        />
      );
      break;
    }
    case PAGENAME.HOTELSPK: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadHotelsPkBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.REPORT1: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadReport1Bi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.REFUNDS_ONELIYA: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadRefundsOneliyaBi }
          nameList={ REPORTNAMELIST.REFUNDS_ONELIYA }
        />
      );
      break;
    }
    case PAGENAME.PROCEEDS: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadProceedsBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.REVISEACTTEST: {
      page = (
        <ReviseActTest
          reviseActTestService={ reviseActTestService }
          onMoveToReport={ moveToReportResult }
        />
      );
      break;
    }
    case PAGENAME.MANAGERSACTIONS: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadManagersActionsBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.OVERDRAFT: {
      page = (
        <SearchReport
          disabledButton
          onDownload={ businessService.downloadOverdraftBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
          description={ REPORTNAME.OVERDRAFTREPORT }
        />
      );
      break;
    }
    case PAGENAME.OVERDRAFT_SEC: {
      page = (
        <Overdraft
          onDownload={ businessService.downloadOverdraftBiSec }
          nameList={ REPORTNAMELIST.REPORTNAME }
          description={ REPORTNAME.OVERDRAFTREPORT }
        />
      );
      break;
    }
    case PAGENAME.ANALYTICSAMO: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadAnalyticsAmoBi }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.HOTEL_VAT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadHotelVat }
          nameList={ REPORTNAMELIST.REPORTNAME }
          description={ REPORTNAME.HOTEL_VAT }
          disabledButton
        />
      );
      break;
    }
    case PAGENAME.AA_VAT: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadAAVat }
          nameList={ REPORTNAMELIST.REPORTNAME }
          description={ REPORTNAME.AA_VAT }
          disabledButton
        />
      );
      break;
    }
    case PAGENAME.SCHEMAS: {
      page = (
        <SchemasReportPage
          onDownload={ businessService.downloadSchemas }
        />
      );
      break;
    }
    case PAGENAME.AUTOSENDINGEDO: {
      page = (
        <AutoSendingEdo
          onDownload={ businessService.autoDownloadSendingEdo }
          businessService={ businessService }
        />
      );
      break;
    }
    case PAGENAME.PAYMENT_CARD: {
      page = (
        <PaymentCard
          onDownload={ businessService.downloadPaymentCard }
          limit={ LIMIT.QUARTER }
        />
      );
      break;
    }
    case PAGENAME.PPR_AND_KONTUR: {
      page = (
        <PaymentCard
          onDownload={ businessService.monetaryTransactions }
          limit={ LIMIT.QUARTER }
        />
      );
      break;
    }
    case PAGENAME.GENERAL_ACT_COMPLETED_WORKS: {
      page = (
        <GeneralActCompletedWorks
          onDownload={ businessService.generalActCompletedWorks }
          businessService={ businessService }
          tripService={ tripService }
        />
      );
      break;
    }
    case PAGENAME.INSURANCE: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadReportInsuranceBi }
          nameList={ REPORTNAMELIST.INSURANCE }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.INSURANCE_SECOND: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadReportInsuranceBiFiltered }
          nameList={ REPORTNAMELIST.INSURANCE_SECOND }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.INSURANCE_THIRD: {
      page = (
        <SearchReport
          onDownload={ businessService.downloadReportInsuranceBiConsolidated }
          nameList={ REPORTNAMELIST.INSURANCE_THIRD }
          maxDate={ maxDate }
        />
      );
      break;
    }
    case PAGENAME.SUPPLIERDOCUMENTS: {
      page = (
        <SupplierDocuments
          onDownload={ businessService.getSupplierDocuments }
          onSend={ businessService.sendSupplierDocumentsCheckbox }
        />
      );
      break;
    }
    case PAGENAME.TRIPBYDATE: {
      page = (
        <TripByDate
          onDownload={ businessService.getTripByDate }
          onSend={ businessService.sendTripByDateCheckboxes }
        />
      );
      break;
    }
    case PAGENAME.CALL_APARTMENTS: {
      page = (
        <CallApartments
          onDownload={ businessService.getApartmentsByDate }
          onSend={ businessService.sendApartmentsByDateCheckboxes }
        />
      );
      break;
    }
    case PAGENAME.REINSURANCE: {
      page = (
        <ReInsurance
          onDownload={ businessService.getReconciliationWithInsurance }
        />
      );
      break;
    }
    case PAGENAME.AVIA_REPORT: {
      page = (
        <AviaReport
          onDownload={ businessService.getAviaReport }
        />
      );
      break;
    }
    case PAGENAME.PROVIDERS: {
      page = (
        <SearchReport
          onDownload={ businessService.getAandaVsProviders }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.RUSHYDRO: {
      page = (
        <SearchReport
          onDownload={ businessService.getWlRushydro }
          nameList={ REPORTNAMELIST.RUSHYDRO }
        />
      );
      break;
    }
    case PAGENAME.AANDA: {
      page = (
        <SearchReport
          onDownload={ businessService.getAandaReport }
          nameList={ REPORTNAMELIST.REPORTNAME }
        />
      );
      break;
    }
    case PAGENAME.MICE: {
      page = (
        <SearchReport
          onDownload={ businessService.getMiceReport }
          nameList={ REPORTNAMELIST.MICE }
        />
      );
      break;
    }
    case PAGENAME.PRINTDOCUMENTS: {
      page = (
        <DocumentsForPrintsReport
          onDownload={ businessService.getPrintDocuments }
          checkStatus={ businessService.getCheckingStatusPrintDocuments }
          businessService={ businessService }
        />
      );
    }
  }

  if (page) {
    const root = createRoot(box);

    root.render(page);

    return root;
  }

  return null;
};

export default renderComponents;
